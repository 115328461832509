import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import lang from '../../utils/lang'
import { resetHeader } from '../../actions/header'
import { formatWorkspaceName } from '../../utils/helperFunctions'
import { EditWorkspaceForm } from '../../components/GUI/EditWorkspaceForm'
import { closeDialog, openModalDialog } from '../../actions/modalDialog'
import { workspacesService } from '../../services/workspaces.service'
import { exposeErrors } from '../../utils/errors'
import NumericInput from '../../components/GUI/NumericInput'
import OptionSwitcher from '../../components/GUI/OptionSwitcher'
import { deleteWorkspaceSetting, setWorkspaceSetting } from '../../actions/user'

class WorkspaceSettings extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: true
        }
    }

    async componentDidMount () {
        this.props.resetHeader()
        this.setState({ loading: false })
    }

    render () {
        if (this.state.loading) {
            return <div className="Settings">
                <div className="simple-page">
                    {this.renderLoadingPlaceholder()}
                    {this.renderLoadingPlaceholder()}
                    {this.renderLoadingPlaceholder()}
                </div>
            </div>
        }
        return (
            <div className="Settings">
                <div className="simple-page">
                    <div className="page-title">{ lang.d('workspace-settings') }</div>
                    {this.renderKeys()}
                </div>
            </div>
        )
    }

    updateWorkspaceNameInState (name) {
        this.props.updateWorkspaceInState({ ...this.props.context.workspace, name })
    }

    updateWorkspaceSecretKeyInState (secretKey) {
        this.props.updateWorkspaceInState({ ...this.props.context.workspace, secretKey })
    }

    editWorkspace () {
        this.props.openModalDialog({
            title: lang.d('edit_workspace_name'),
            successMessage: lang.d('workspace_updated'),
            onSubmit: values => workspacesService.updateWorkspace(this.props.context.workspace.key, values.name),
            onSubmitSuccess: values => this.updateWorkspaceNameInState(values.name),
            settings: {
                waitOnAccept: true,
                acceptCaption: lang.d('update'),
                formik: {
                    initialValues: {
                        name: formatWorkspaceName(this.props.context.workspace) || ''
                    },
                    validate: values => {
                        const errors = {}
                        if (values.name === '') {
                            errors.name = lang.d('required')
                        }
                        return errors
                    },
                    render: props => <EditWorkspaceForm {...props} />
                }
            }
        })
    }

    regenerateSecretKey () {
        this.props.openModalDialog({
            title: lang.d('regenerate_workspace_secret_key'),
            message: lang.d('regenerate_workspace_secret_key_confirmation', { workspace: formatWorkspaceName(this.props.context.workspace) }),
            successMessage: lang.d('workspace_secret_key_regenerated'),
            settings: {
                onAccept: () => this.handleRegenerateSecretKey(),
                acceptCaption: lang.d('regenerate'),
                dangerousAction: true,
                waitOnAccept: true
            }
        })
    }

    renderKeys () {
        return <>
            <div className="setting">
                <div className="title">{lang.d('name')} </div>
                <div className="key editable">
                    <div>{formatWorkspaceName(this.props.context.workspace)}</div>
                    <div className="btn-container">
                        <button id="editWorkspaceButton" className="button-link"
                            onClick={this.editWorkspace.bind(this)}><span className="icon icon-edit"/></button>
                    </div>
                </div>
            </div>
            <div className="setting">
                <div className="notice">
                    <div className="title">{lang.d('security_notice')}</div>
                    <div className="description">{lang.d('security_notice_secret_key')}</div>
                </div>
                <div className="title">{lang.d('workspace_secret_key')}</div>
                <div className="key editable">
                    <div>{this.props.context.workspace.secretKey}</div>
                    <div className="btn-container">
                        <button id="regenerateSecretKeyButton" title={lang.d('regenerate_workspace_secret_key')}
                            className="button-link" onClick={this.regenerateSecretKey.bind(this)}><span
                                className="icon icon-refresh"/></button>
                    </div>
                </div>
            </div>
            <div className="setting">
                <div className="notice">
                    <div className="title">{lang.d('what_is_workspace_key')}</div>
                    <div className="description">{lang.d('workspace_key_extra_info')}</div>
                </div>
                <div className="title">{lang.d('workspace_key')}</div>
                <div className="key">{this.props.context.workspace.key}</div>
            </div>
            {this.holdPeriodInMinutesSetting()}
        </>
    }

    holdPeriodInMinutesSetting () {
        return <div className="setting">
            <div className="notice">
                <div className="title">{lang.d('hint')}</div>
                <div className="description">
                    <p>{lang.d('hold_period_description')}</p>
                    <p><a className="arrow"
                        href="https://docs.seats.io/docs/renderer-config-session" target="_blank"
                        rel="noopener noreferrer">{lang.d('read_more')}</a></p>
                </div>
            </div>
            <div className="title">{lang.d('hold_period')}</div>
            <div className="text">{lang.d('hold_period_usage')}</div>

            <OptionSwitcher
                loading={this.state.updatingOverrideHoldPeriod}
                value={this.props.context.workspace.overriddenSettings.holdPeriodInMinutes === undefined ? 'COMPANY' : 'WORKSPACE'}
                options={{
                    COMPANY: lang.d('use_company_setting'),
                    WORKSPACE: lang.d('use_workspace_setting')
                }}
                onChange={value => this.setOverrideHoldPeriod(value === 'WORKSPACE')}
            />

            <NumericInput
                value={this.props.context.workspace.overriddenSettings.holdPeriodInMinutes || this.props.context.company.settings.holdPeriodInMinutes}
                pixelsForStep={3}
                min={1}
                max={120}
                unit={lang.d('minutes_short')}
                disableSteppers={true}
                loading={this.state.updatingHoldPeriod}
                onChangeFinal={value => this.setHoldPeriod(value)}
                title={lang.d('hold_period_in_minutes')}
                disabled={this.props.context.workspace.overriddenSettings.holdPeriodInMinutes === undefined}
            />
        </div>
    }

    async setOverrideHoldPeriod (override) {
        this.setState({
            updatingOverrideHoldPeriod: true
        })
        try {
            if (override) {
                this.setState({
                    holdPeriodInMinutes: this.props.context.company.settings.holdPeriodInMinutes
                })
                await exposeErrors(workspacesService.setHoldPeriod(this.state.holdPeriodInMinutes || this.props.context.company.settings.holdPeriodInMinutes))
                this.props.setSetting('holdPeriodInMinutes', this.state.holdPeriodInMinutes)
            } else {
                this.setState({
                    holdPeriodInMinutes: undefined
                })
                await exposeErrors(workspacesService.unsetHoldPeriod())
                this.props.deleteSetting('holdPeriodInMinutes')
            }
        } finally {
            this.setState({
                updatingOverrideHoldPeriod: false
            })
        }
    }

    async setHoldPeriod (value) {
        this.setState({
            updatingHoldPeriod: true
        })
        try {
            await exposeErrors(workspacesService.setHoldPeriod(value))
            this.props.setSetting('holdPeriodInMinutes', value)
        } finally {
            this.setState({
                updatingHoldPeriod: false
            })
        }
    }

    renderLoadingPlaceholder () {
        return <div className="loading-placeholder">
            <div className="fake-item"></div>
            <div className="fake-item"></div>
            <div className="fake-item"></div>
        </div>
    }

    async handleRegenerateSecretKey () {
        const newSecretKey = await exposeErrors(workspacesService.regenerateSecretKey(this.props.context.workspace.key), () => this.props.closeModalDialog())
        this.updateWorkspaceSecretKeyInState(newSecretKey)
    }
}

WorkspaceSettings.propTypes = {
    context: PropTypes.object.isRequired,
    resetHeader: PropTypes.func.isRequired,
    updateWorkspaceInState: PropTypes.func.isRequired,
    openModalDialog: PropTypes.func.isRequired,
    closeModalDialog: PropTypes.func.isRequired,
    setSetting: PropTypes.func.isRequired,
    deleteSetting: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    context: state.context
})

const mapDispatchToProps = dispatch => ({
    setSetting: (setting, value) => dispatch(setWorkspaceSetting(setting, value)),
    deleteSetting: (setting) => dispatch(deleteWorkspaceSetting(setting)),
    resetHeader: () => dispatch(resetHeader()),
    updateWorkspaceInState: workspace => dispatch({ type: 'UPDATE_WORKSPACE', workspace }),
    openModalDialog: payload => dispatch(openModalDialog(payload)),
    closeModalDialog: payload => dispatch(closeDialog(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSettings)
