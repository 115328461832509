import { getClient } from './base.service'
import axios from 'axios'
import { backendUrlOfActiveCompany } from '../domain/regions'
import store from '../store'

export const workspacesService = {
    listFirstPage,
    createWorkspace,
    updateWorkspace,
    retrieveWorkspace,
    listAllWorkspaces,
    listActiveWorkspaces,
    listActiveWorkspacesAfterId,
    listInactiveWorkspaces,
    regenerateSecretKey,
    activateWorkspace,
    deactivateWorkspace,
    setDefault,
    setHoldPeriod,
    unsetHoldPeriod
}

function retrieveWorkspace (key) {
    return axios({
        method: 'get',
        url: `${backendUrlOfActiveCompany()}/workspaces/${key}`,
        auth: { username: store.getState().user.secretKey },
        headers: { 'X-Company-ID': store.getState().context.company.id }
    }).then(response => response.data)
}

function listFirstPage (pageSize, searchString = null) {
    return getClient().workspaces.listActiveFirstPage(searchString, pageSize)
}

function createWorkspace (name, isTest) {
    return getClient().workspaces.create(name, isTest)
}

function updateWorkspace (key, name) {
    return getClient().workspaces.update(key, name)
}

function regenerateSecretKey (key) {
    return getClient().workspaces.regenerateSecretKey(key)
}

function activateWorkspace (key) {
    return getClient().workspaces.activate(key)
}

function deactivateWorkspace (key) {
    return getClient().workspaces.deactivate(key)
}

function setHoldPeriod (value) {
    return axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/workspaces/${store.getState().context.workspace.key}/settings`,
        auth: { username: store.getState().user.secretKey },
        data: {
            key: 'HOLD_PERIOD_IN_MINUTES',
            value: value.toString()
        },
        headers: { 'X-Company-ID': store.getState().context.company.id }
    })
}

function unsetHoldPeriod () {
    return axios({
        method: 'delete',
        url: `${backendUrlOfActiveCompany()}/workspaces/${store.getState().context.workspace.key}/settings/HOLD_PERIOD_IN_MINUTES`,
        auth: { username: store.getState().user.secretKey },
        headers: { 'X-Company-ID': store.getState().context.company.id }
    })
}

function setDefault (key) {
    return getClient().workspaces.setDefault(key)
}

async function listAllWorkspaces (searchString = null) {
    const workspaces = []
    for await (const workspace of getClient().workspaces.listAll(searchString)) {
        workspaces.push(workspace)
    }
    return workspaces
}

async function listActiveWorkspaces (searchString = null) {
    const workspaces = []
    for await (const workspace of getClient().workspaces.listActive(searchString)) {
        workspaces.push(workspace)
    }
    return workspaces
}

function listActiveWorkspacesAfterId (id) {
    return getClient().workspaces.listActivePageAfter(id)
}

async function listInactiveWorkspaces (searchString = null) {
    const workspaces = []
    for await (const workspace of getClient().workspaces.listInactive(searchString)) {
        workspaces.push(workspace)
    }
    return workspaces
}
