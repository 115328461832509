import React from 'react'
import * as PropTypes from 'prop-types'
import lang from '../../utils/lang'
import newFeatures from '../../config/newFeatures.json'
import { loadLastNotifiedFeature, saveLastNotifiedFeature } from '../../utils/localStorage'
import { connect } from 'react-redux'
import { closeAndClearDialog } from '../../actions/modalDialog'

export const LATEST_FEATURE = newFeatures.features[0].id

class NewFeaturesList extends React.PureComponent {
    constructor (props) {
        super(props)
        this.featuresListRef = React.createRef()
    }

    componentDidMount () {
        if (loadLastNotifiedFeature() !== LATEST_FEATURE) {
            saveLastNotifiedFeature(LATEST_FEATURE)
        }
    }

    onOutsideClick (e) {
        if (this.featuresListRef.current === e.target) {
            this.props.dispatch(closeAndClearDialog())
        }
    }

    onClose () {
        this.props.dispatch(closeAndClearDialog())
    }

    render () {
        return <div className="NewFeaturesList" onClick={this.onOutsideClick.bind(this)}>
            <div className="go-back-button" onClick={this.onClose.bind(this)}>
                <span className="icon icon-x"></span>
                <span className="label">{ lang.d('close') }</span>
            </div>
            <div className="title">{ lang.d('whats_new') }</div>
            <div className="features-list" ref={this.featuresListRef}>
                <a className="feature-link" href="https://docs.seats.io/changelog/" rel="external" target="_blank">{ lang.d('see_changelog') }</a>
                { newFeatures.features.map(feature => <Feature {...feature} key={feature.id} />) }
            </div>
        </div>
    }
}

NewFeaturesList.propTypes = {
    dispatch: PropTypes.func.isRequired
}

export default connect()(NewFeaturesList)

function Feature ({ id, href, filetype, title, body, callToAction }) {
    const isVideo = filetype === 'mov'
    const src = `/static/new-features/${id}@2x.${filetype}`

    return <a href={href} className={`Feature ${href && 'with-link'}`} target="_blank" rel="noopener noreferrer">
        { isVideo
            ? <video src={src} muted autoPlay loop />
            : <img src={src} alt={title} />
        }
        <div className="details">
            <div className="title">{ title }</div>
            <div className="body">{ body }</div>
            { callToAction && <div className="link external-link">{callToAction}</div>}
        </div>
    </a>
}

Feature.propTypes = {
    id: PropTypes.string.isRequired,
    filetype: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    href: PropTypes.string,
    callToAction: PropTypes.string
}
