import React from 'react'
import { downloadInvoice, getInvoiceUrl, retrieveInvoices } from '../services/invoices.service'
import lang from '../utils/lang'
import { setHeader } from '../actions/header'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '../components/GUI/Button'
import { contextualMenuService } from '../services/contextualMenu.service'
import { notificationService } from '../services/notification.service'
import { exposeErrors } from '../utils/errors'
import { upperFirst } from 'lodash'
import { Environment } from '../environment'

class Invoices extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            invoices: null,
            contextualMenuOpened: false
        }
    }

    async componentDidMount () {
        const leftItems = Environment.allowDirectStripeIntegration ? [{
            to: '/update-payment-method',
            icon: 'icon-plus-bold',
            string_key: 'update_payment_method',
            highlighted: true
        }] : []
        this.props.setHeader({
            showSearch: false,
            hasItems: true,
            centerItems: [],
            rightItems: [],
            leftItems: leftItems
        })
        await this.loadInvoices()
        if (this.props.location && this.props.location.state && this.props.location.state.messageKey) {
            notificationService.info(lang.d(this.props.location.state.messageKey), lang.d(`${this.props.location.state.messageKey}_message`))
        }
    }

    async loadInvoices () {
        retrieveInvoices()
            .then(invoices => this.setState({invoices: invoices}))
    }

    onContextMenu (e, invoiceId, invoiceName, invoiceStatus, invoiceUrl, pdfUrl) {
        this.setState({ contextualMenuOpened: true })
        contextualMenuService.show([
            {
                type: 'download',
                caption: lang.d('download'),
                uiEvent: 'downloadInvoice'
            },
            {
                type: 'external-link',
                caption: lang.d(invoiceStatus === 'paid' ? 'view_invoice' : 'view_pay_invoice'),
                uiEvent: 'viewInvoice'
            }
        ], {
            onSelect: uiEvent => this.onAction(uiEvent, invoiceId, invoiceName, invoiceUrl, pdfUrl),
            onClose: () => this.setState({ contextualMenuOpened: false }),
            position: {
                left: e.clientX,
                top: e.clientY
            }
        })
    }

    async onAction (uiEvent, invoiceId, invoiceNumber, invoiceUrl, pdfUrl) {
        switch (uiEvent) {
        case 'downloadInvoice':
            await this.doDownloadInvoice(invoiceId, invoiceNumber, pdfUrl)
            break
        case 'viewInvoice':
            this.doViewInvoice(invoiceId, invoiceUrl)
            break
        default:
            break
        }
    }

    async doDownloadInvoice (invoiceId, invoiceNumber, pdfUrl) {
        if (pdfUrl) {
            window.open(pdfUrl, "_blank", "noreferrer");
        } else {
            notificationService.info(lang.d('downloading'), lang.d('downloading_invoice', {invoice_number: invoiceNumber}), 3000)
            await exposeErrors(downloadInvoice(invoiceId, invoiceNumber))
        }
    }

    doViewInvoice (invoiceId, invoiceUrl) {
        if (invoiceUrl) {
            window.open(invoiceUrl, "_blank", "noreferrer");
        } else {
            window.open(getInvoiceUrl(invoiceId), "_blank", "noreferrer");
        }
    }

    render () {
        return <div className="Invoices" key={`Invoices-page-${this.state.requestedPage}`}>
            <div className="container">
                <div className="title">{lang.d('invoices')}</div>
                { this.state.invoices
                    ? this.renderTable()
                    : this.renderPlaceholder()
                }
            </div>
        </div>
    }

    renderTable () {
        return <div className="DataTable">
            <table>
                <thead>
                    <tr>
                        { this.renderHeaders() }
                    </tr>
                </thead>
                <tbody>
                    { this.renderRows() }
                </tbody>
            </table>
        </div>
    }

    renderHeaders () {
        return <>
            <th style={{width: '24%'}}>{lang.d('due_date')}</th>
            <th style={{width: '24%'}}>{lang.d('invoice_number')}</th>
            <th style={{width: '24%'}}>{lang.d('amount')}</th>
            <th style={{width: '24%'}}>{lang.d('status')}</th>
            <th style={{width: '4%'}}></th>
        </>
    }

    renderRows () {
        return this.state.invoices
            .map((invoice) => {
                const total = invoice.total.toFixed(2)
                const dueDate = new Date(invoice.dueDate).toLocaleDateString('en-gb')
                const status = upperFirst(invoice.status)
                return <tr key={invoice.invoiceId}>
                    <td>{dueDate}</td>
                    <td>{invoice.invoiceNumber}</td>
                    <td>{invoice.currencySymbol}{total}</td>
                    <td>{status}</td>
                    <td>
                        <Button preset="round" type="more" onClick={e => this.onContextMenu(e, invoice.invoiceId, invoice.invoiceNumber, invoice.status, invoice.invoiceUrl, invoice.pdfUrl)}/>
                    </td>
                </tr>
            })
    }

    renderPlaceholder () {
        return <div className="DataTable">
            <div className="loading-placeholder">
                <div className="fake-item double"></div>
                <div className="fake-item"></div>
                <div className="fake-item"></div>
            </div>
        </div>
    }
}

Invoices.propTypes = {
    setHeader: PropTypes.func,
    location: PropTypes.object
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(Invoices)
