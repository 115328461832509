import { getClient } from './base.service'
import { UpdateEventParams } from 'seatsio'

export const eventsService = {
    getEvents,
    deleteEvent,
    retrieveEvent,
    reportEventSummaryByStatus,
    reportEventSummaryByAvailabilityReason,
    updateEventKey,
    updateEventName,
    create,
    getStatusChangesInFirstPage,
    getStatusChangesAfterId,
    markAsInThePast
}

function getEvents () {
    return getClient().events.listAll()
}

function deleteEvent (key) {
    return getClient().events.delete(key)
}

function retrieveEvent (key) {
    return getClient().events.retrieve(key)
}

function reportEventSummaryByStatus (key) {
    return getClient().eventReports.summaryByStatus(key)
}

function reportEventSummaryByAvailabilityReason (key) {
    return getClient().eventReports.summaryByAvailabilityReason(key)
}

function updateEventKey (oldKey, newKey) {
    return getClient().events.update(oldKey, new UpdateEventParams().withKey(newKey))
}

function updateEventName (eventKey, newName) {
    return getClient().events.update(eventKey, new UpdateEventParams().withName(newName))
}

function create (chartKey) {
    return getClient().events.create(chartKey, undefined)
}

function getStatusChangesInFirstPage (eventKey, pageSize, params = {}) {
    return getClient().events.statusChanges(eventKey).firstPage(params, pageSize)
}

function getStatusChangesAfterId (eventKey, afterId, pageSize, params = {}) {
    return getClient().events.statusChanges(eventKey).pageAfter(afterId, params, pageSize)
}

function markAsInThePast(key) {
    return getClient().events.update(key, new UpdateEventParams().withIsInThePast(true))
}
