import React from 'react'
import PropTypes from 'prop-types'
import { setHeader } from '../../actions/header'
import { connect } from 'react-redux'
import { getUpgradeSubscriptionPlan } from '../../services/accounts.service'

class UpgradeSummary extends React.Component {

    constructor (props) {
        super(props)
        this.state = {
            requestedSubscriptionPlan: null
        }
    }

    async componentDidMount () {
        this.props.setHeader({})
        const requestedSubscriptionPlan = await getUpgradeSubscriptionPlan()
        this.setState({requestedSubscriptionPlan})
    }

    renderSummary () {
        return <div>
            <div>Thank you!</div>
            {this.state && this.state.requestedSubscriptionPlan && <div>Your {this.state.requestedSubscriptionPlan.caption} subscription will be active in a few minutes</div>}
        </div>
    }

    render () {
        return <div className="PaymentSummary">
            <div className="sectioned-page">
                <div className="sectioned-page-container">
                    <div className="section">
                        { this.renderSummary() }
                    </div>
                </div>
            </div>
        </div>
    }
}

UpgradeSummary.propTypes = {
    setHeader: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
    setHeader: payload => dispatch(setHeader(payload))
})

export default connect(null, mapDispatchToProps)(UpgradeSummary)
