import axios from 'axios'
import { backendUrlOfActiveCompany } from '../domain/regions'
import store from '../store'
import { Environment } from '../environment'
const { invoiceUrl } = Environment

export const retrieveInvoices = async () => {
    const result = await axios({
        method: 'get',
        url: `${backendUrlOfActiveCompany()}/invoices`,
        auth: { username: store.getState().user.secretKey },
        headers: { 'X-Company-ID': store.getState().context.company.id }
    })
    return result.data
}

export const downloadInvoice =  async (invoiceId, invoiceNumber) => {
    return axios({
        method: 'get',
        url: `${backendUrlOfActiveCompany()}/invoices/${invoiceId}`,
        auth: { username: store.getState().user.secretKey },
        headers: { 'Accept': 'application/pdf', 'X-Company-ID': store.getState().context.company.id },
        responseType: 'blob'
    })
        .then(response => response.data)
        .then(pdf => {
            const url = window.URL.createObjectURL(
                new Blob([pdf]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${invoiceNumber}.pdf`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
}

export const getInvoiceUrl = (invoiceId) => {
    return `${invoiceUrl}/${invoiceId}`
}
