import React from 'react'
import { changePassword } from '../../services/accounts.service'
import { parseBackendErrors } from '../../utils/backendErrors'
import FormButton from '../../components/GUI/FormButton'
import { useFormik } from 'formik'
import lang from '../../utils/lang'
import * as PropTypes from 'prop-types'

const ChangePasswordForm = () => {
    const formik = useFormik({
        initialValues: { password: '', confirmPassword: '' },
        validate: (values, props) => {
            const errors = {}

            if (values.password.length < 8) {
                errors.confirmPassword = lang.d('password_short')
            }

            if (values.password !== values.confirmPassword) {
                errors.confirmPassword = lang.d('password_mismatch')
            }

            if (!values.password) {
                errors.password = lang.d('required')
            }

            if (!values.confirmPassword) {
                errors.confirmPassword = lang.d('required')
            }
            formik.setStatus({})
            return errors
        },
        onSubmit: async (values, { setErrors, setStatus }) => {
            try {
                await changePassword(values.password)
                values.password = ''
                values.confirmPassword = ''
                setStatus({ success: lang.d('password_changed') })
            } catch (e) {
                const { messages } = e
                setErrors(parseBackendErrors(messages))
                setStatus({ failed: true })
            }
        },
        displayName: 'ChangePasswordForm'
    })

    const {
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        isSubmitting,
        dirty,
        status
    } = formik

    const passwordErrors = errors.password && touched.password
    const confirmPasswordErrors = errors.confirmPassword && touched.confirmPassword

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-field">
                <label htmlFor="password">{lang.d('password')}</label>
                <input id="password"
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.password && touched.password && 'error'}
                />
            </div>

            {passwordErrors &&
            <div className="errors">
                <div className="error">{errors.password}</div>
            </div>
            }

            <div className="form-field">
                <label htmlFor="confirmPassword">{lang.d('confirm_password')}</label>
                <input id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={errors.confirmPassword && touched.confirmPassword && 'error'}
                />
            </div>

            {confirmPasswordErrors &&
            <div className="errors">
                <div className="error">{errors.confirmPassword}</div>
            </div>
            }
            { status && !(confirmPasswordErrors || passwordErrors) && status.success &&
                <div className="success"> {status.success} </div>
            }
            <FormButton type="submit" loading={isSubmitting} disabled={!dirty || isSubmitting}>
                {lang.d('change_password')}
            </FormButton>
        </form>
    )
}

ChangePasswordForm.propTypes = {
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.object,
    status: PropTypes.object,
    isSubmitting: PropTypes.bool,
    dirty: PropTypes.bool
}

export {
    ChangePasswordForm
}
