import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { setHeader } from '../../actions/header'
import LoadingIndicator from '../../components/GUI/LoadingIndicator'
import lang from '../../utils/lang'
import { editAccount, getAccount } from '../../services/accounts.service'
import { showFailedToContactServerError } from '../../utils/errors'
import { pricingPlanDescription } from './PricingPlans'

const initialValues = {
    subscriptionPlan: ''
}

const validationSchema = yup.object().shape({
    subscriptionPlan: yup.string().required()
})

const ChangePlan = (props) => {
    const dispatch = useDispatch()
    const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState(undefined)
    const [upcomingSubscriptionPlan, setUpcomingSubscriptionPlan] = useState(undefined)
    const [requestedSubscriptionPlan, setRequestedSubscriptionPlan] = useState(undefined)
    const [loaded, setLoaded] = useState(false)
    const history = useHistory()

    useEffect(() => {
        dispatch(setHeader({
            leftItems: [{
                to: '/account',
                icon: 'icon-arrow-light-left',
                string_key: 'account',
                className: 'soft-button'
            }]
        }))
    }, [dispatch, history])

    useEffect(() => {
        const fetchSubscriptionPlans = async () => {
            const account = await getAccount()
            setCurrentSubscriptionPlan(account.company.currentSubscriptionPlan)
            setUpcomingSubscriptionPlan(account.company.upcomingSubscriptionPlan)
            setRequestedSubscriptionPlan(account.company.requestedSubscriptionPlan)
            setLoaded(true)
        }
        fetchSubscriptionPlans()
    }, [])

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values, { setStatus }) => {
            try {
                await editAccount(values)
                setStatus('submitted')
            } catch (e) {
                showFailedToContactServerError(e)
                setStatus(null)
            }
        }
    })

    function option (value, caption) {
        if (upcomingSubscriptionPlan) {
            if (upcomingSubscriptionPlan.key !== value) {
                return <option value={value}>{caption}</option>
            }
        } else if (currentSubscriptionPlan && currentSubscriptionPlan.key !== value) {
            return <option value={value}>{caption}</option>
        }
    }

    function currentPlanNotice () {
        if (upcomingSubscriptionPlan) {
            return <p>You are currently subscribed to the {currentSubscriptionPlan.caption} pricing plan.<br/>
                From the 1st of next month, <b>{upcomingSubscriptionPlan.caption}</b> will be applied, unless you change it below.</p>
        }
        if (requestedSubscriptionPlan) {
            return <p>
                Your account will soon be upgraded to <b>{requestedSubscriptionPlan.caption}</b>. We'll notify you by email. <br/>
                Feel free to reach out to <a href="mailto:sales@seats.io">sales@seats.io</a> in case of questions!
            </p>
        }
        if (currentSubscriptionPlan.key === 'NOT_SET') {
            return <p>You are currently not subscribed to a paid plan. <br/>
                Please reach out to <a href="mailto:sales@seats.io">sales@seats.io</a> if you want to re-activate your account.
            </p>
        }
        return <p>You're currently subscribed to the <b>{currentSubscriptionPlan.caption}</b> pricing plan.</p>
    }

    function changePlanForm () {
        const errorClassName = fieldName => formik.errors[fieldName] ? 'error' : null
        return <>
            <div className="form-field">
                <div className="description">
                    <label htmlFor="subscriptionPlan">New pricing plan</label>
                    <a href="https://www.seats.io/pricing" className="hint external-link" target="_blank"
                        rel="noopener noreferrer">{lang.d('pricing-plan-link')}</a>
                </div>
                <div className="field">
                    <select id="subscriptionPlan" name="subscriptionPlan" onChange={formik.handleChange} value={formik.values.subscriptionPlan} className={errorClassName('subscriptionPlan')}>
                        <option value="" disabled>{lang.d('please_select')}</option>
                        {option('YEARLY_SILVER', 'Silver (yearly)')}
                        {option('MONTHLY_GOLD', 'Gold (monthly)')}
                        {option('YEARLY_GOLD', 'Gold (yearly)')}
                        {option('MONTHLY_DIAMOND', 'Diamond (monthly)')}
                        {option('YEARLY_DIAMOND', 'Diamond (yearly)')}
                        {option('MONTHLY_PLATINUM', 'Platinum (monthly)')}
                        {option('YEARLY_PLATINUM', 'Platinum (yearly)')}
                        {option('MONTHLY_MITHRIL', 'Mithril (monthly)')}
                        {option('YEARLY_MITHRIL', 'Mithril (yearly)')}
                    </select>
                    {formik.values.subscriptionPlan &&
                        <div className="hint dark">
                            {pricingPlanDescription(formik.values.subscriptionPlan)}
                        </div>
                    }
                </div>
            </div>
            <div className="form-text">
                <p>{lang.d('pricing_plan_begin_notice')}</p>
                <p>{lang.d('change_pricing_plan_legal_notice')}</p>
                <p><a href="https://www.seats.io/legal/terms" target="_blank" className="external-link"
                    rel="noopener noreferrer">{lang.d('read-terms-of-use')}</a></p>
            </div>
            <div className="form-actions">
                {formik.isSubmitting
                    ? <LoadingIndicator/>
                    : <input type="submit" value="Confirm" disabled={!formik.values.subscriptionPlan}/>
                }
            </div>
        </>
    }

    const renderFormik = () => {
        if (!loaded) {
            return null
        }

        return (
            <form className={`two-column ${formik.isSubmitting && 'submitting'} ${formik.status}`}
                onSubmit={formik.handleSubmit}>
                <div className="submitted-notice">
                    <div className="display-title">{lang.d('upgrade-requested-title')}</div>
                    <div className="display-text">{lang.d('upgrade-requested-description')}</div>
                </div>
                <div className="display-title">Change your pricing plan</div>

                <div className="form-text">
                    { currentPlanNotice() }
                </div>

                { currentSubscriptionPlan.key !== 'NOT_SET' && changePlanForm() }
            </form>
        )
    }

    return <div className="Upgrade">
        <div className="sectioned-page">
            <div className="sectioned-page-container">
                <div className="section">
                    { renderFormik() }
                </div>
            </div>
        </div>
    </div>
}

export default ChangePlan
