import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { resetHeader } from '../../actions/header'
import lang from '../../utils/lang'

class Account extends React.Component {
    componentDidMount () {
        this.props.resetHeader()
    }

    render () {
        if (this.props.user.company.hasChosenSubscriptionPlan) {
            return <Redirect to={{ pathname: '/', state: { from: '/account' } }}/>
        }

        return <div className="Admin sectioned-page">
            <div className="sectioned-page-container">
                <div className="section">
                    <div className="title">{ this.props.user.email }</div>
                    <div className="link-list wide">
                        <Link to="/account/upgrade" className="arrow">{ lang.d('upgrade') }</Link>
                        <Link to="/logout" className="arrow">{ lang.d('logout') }</Link>
                    </div>
                </div>
            </div>
        </div>
    }
}

Account.propTypes = {
    resetHeader: PropTypes.func.isRequired,
    user: PropTypes.object
}

const mapStateToProps = state => ({
    user: state.user
})

const mapDispatchToProps = dispatch => ({
    resetHeader: () => dispatch(resetHeader())
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)
