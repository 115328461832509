import axios from 'axios'
import store from '../store'
import { getClient } from './base.service'
import { backendUrlOfActiveCompany } from '../domain/regions'
import { Account } from '../domain/account'

const getServiceClient = () => getClient().accounts

export const changePassword = password => getServiceClient().changePassword(password)

export const changeDraftsValue = value => {
    if (value === 'TRUE') {
        return getServiceClient().enableDraftChartDrawings()
    } else if (value === 'FALSE') {
        return getServiceClient().disableDraftChartDrawings()
    }
}

export const changeHoldPeriod = period => getServiceClient().changeHoldPeriod(period)

export const changeSetting = (key, value) => getServiceClient().updateSetting(key, value)

export const getAccount = () => {
    return getServiceClient().client.get(`${backendUrlOfActiveCompany()}/accounts/me`)
        .then(response => new Account(response.data))
}

export const postUpgradeAccountInfo = (info) => {
    return axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/accounts/me/actions/upgrade-account`,
        auth: { username: store.getState().user.secretKey },
        data: info
    })
}

export const postImmediateUpgradeAccountInfo = (info) => {
    return axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/accounts/me/actions/upgrade-account-immediately`,
        auth: { username: store.getState().user.secretKey },
        data: info
    })
}

export const prepareForPayment = async () => {
    let response = await axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/accounts/me/actions/prepare-for-payment`,
        auth: {username: store.getState().user.secretKey},
        data: {}
    })
    return response.data
}

export const editAccount = (info) => {
    return axios({
        method: 'post',
        url: `${backendUrlOfActiveCompany()}/accounts/me/actions/edit`,
        auth: { username: store.getState().user.secretKey },
        data: info,
        headers: { 'X-Company-ID': store.getState().context.company.id }
    })
}

export const getUpgradeSubscriptionPlan = async () => {
    let response = await axios({
        method: 'get',
        url: `${backendUrlOfActiveCompany()}/accounts/me/upgrade-subscription-plan`,
        auth: { username: store.getState().user.secretKey },
        headers: { 'X-Company-ID': store.getState().context.company.id }
    })
    return response.data
}
