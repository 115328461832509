const pricingPlans = {
    YEARLY_SILVER: { committedSeats: '2,400', pricePerSeat: '0.15', committedEuros: '360' },
    MONTHLY_GOLD: { committedSeats: '5,000', pricePerSeat: '0.10', committedEuros: '500' },
    YEARLY_GOLD: { committedSeats: '60,000', pricePerSeat: '0.10', committedEuros: '6,000' },
    MONTHLY_DIAMOND: { committedSeats: '20,000', pricePerSeat: '0.08', committedEuros: '1,600' },
    YEARLY_DIAMOND: { committedSeats: '240,000', pricePerSeat: '0.08', committedEuros: '19,200' },
    MONTHLY_PLATINUM: { committedSeats: '50,000', pricePerSeat: '0.07', committedEuros: '3,500' },
    YEARLY_PLATINUM: { committedSeats: '600,000', pricePerSeat: '0.07', committedEuros: '42,000' },
    MONTHLY_MITHRIL: { committedSeats: '100,000', pricePerSeat: '0.06', committedEuros: '6,000' },
    YEARLY_MITHRIL: { committedSeats: '1,200,000', pricePerSeat: '0.06', committedEuros: '72,000' }
}

function getPeriodicity(selectedPricingPlan) {
    const yearly = selectedPricingPlan.startsWith('YEARLY')
    return yearly ? 'year' : 'month'
}

export const pricingPlanDescription = (selectedPricingPlan) => {
    const periodicity = getPeriodicity(selectedPricingPlan)
    const pricingPlan = pricingPlans[selectedPricingPlan]
    return `${pricingPlan.committedSeats} used seats for €${pricingPlan.committedEuros} per ${periodicity}. €${pricingPlan.pricePerSeat} per additional used seat.`
}

export const firmCommitmentConfirmation = (selectedPricingPlan) => {
    const periodicity = getPeriodicity(selectedPricingPlan)
    const pricingPlan = pricingPlans[selectedPricingPlan]
    return <span>This is a firm commitment to pay <b>at least €{pricingPlan.committedEuros} per {periodicity}</b>.</span>
}
